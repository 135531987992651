
import IMask from 'imask';

import { Client } from '../../common/client';

const feedbackButton = document.querySelector('.feedback-btn');
const feedbackModalDiv = document.querySelector('#form-feedback');
const feedbackForm = document.forms.feedback;

const feedbackPhoneInput = document.querySelector('#feedbackPhone');
const phoneErrorDiv = document.querySelector('#feedback-phone-error');

const feedbackEmailInput = document.querySelector('#feedbackEmail');
const emailErrorDiv = document.querySelector('#feedback-email-error');

const responseErrorDiv = document.querySelector('#feedback-response-error');
const feedbackSuccessMessage = document.querySelector('#feedback-success-msg');

const phoneLength = 11;
const feedbackPhone = IMask(
    feedbackPhoneInput, {
        mask: '+{7} (000) 000-00-00',
    });

feedbackButton.addEventListener('click', () => {
    feedbackModalDiv.classList.add('modal-active');
});

feedbackPhoneInput.addEventListener('keydown', () => inputKeyDownActions(phoneErrorDiv, feedbackPhoneInput));
feedbackEmailInput.addEventListener('keydown', () => inputKeyDownActions(emailErrorDiv, feedbackEmailInput));

const inputKeyDownActions = (errorDiv, input) => {
    if (errorDiv.classList.contains('_show')) {
        errorDiv.classList.remove('_show');
    }

    if (input.classList.contains('input__error')) {
        input.classList.remove('input__error');
    }
};

feedbackForm.onsubmit = async function(e) {
    e.preventDefault();

    if (responseErrorDiv.classList.contains('_show')) {
        responseErrorDiv.classList.remove('_show');
    }

    const formValid = validateFormInputs();

    if (formValid) {
        const comment = this.feedbackComment.value || 'Пожалуйста, свяжитесь со мной';

        const data = {
            companyName: this.feedbackCompanyName.value,
            name: this.feedbackName.value,
            phone: '+' + feedbackPhone.unmaskedValue,
            email: this.feedbackEmail.value,
            comment,
        };
    
        try {
            const resp = await Client.sendFeedback(data);
    
            if (resp.status != 200) {
                Client.logClientError(result.message);
                responseErrorDiv.classList.add('_show');
                return;
            }
    
            let result = await resp.json();
    
            if (result.status === 'FAIL') {
                Client.logClientError(result.message);
                responseErrorDiv.classList.add('_show');
                return;
            }
            // Скрываем форму и отображаем сообщение об успешной отправке
            showSuccessMsg();
            e.target.reset();
        } catch (error) {
            Client.logClientError(error.message);
            responseErrorDiv.classList.add('_show');
        }
    }
};

const validateFormInputs = () => {
    if (feedbackPhone.unmaskedValue.length < phoneLength) {
        // Отображаем ошибку ввода номера телефона
        showError(phoneErrorDiv, feedbackPhoneInput);
        return false;
    }
    if (feedbackEmailInput.value && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(feedbackEmailInput.value)) {
        // Отображаем ошибку ввода email'a
        showError(emailErrorDiv, feedbackEmailInput);
        return false;
    }
    return true;
};

const showError = (errorDiv, input) => {
    errorDiv.classList.add('_show');
    input.classList.add('input__error');
};

const showSuccessMsg = () => {
    feedbackForm.classList.add('d-none');
    feedbackSuccessMessage.classList.add('_show');
};